import React, { Component } from "react";
import PageContainer from "./components/PageContainer";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'bootstrap/dist/js/bootstrap.min';
import Accordion from "./components/Accordion";
import MapCardBox from "./components/MapCardBox";

const bunny = require('./images/bunny.png');
const backpack = require('./images/backpack.png');
const horse = require('./images/goldnohaodkone.png');

class Homepage extends Component<any, any> {

	public render() {
		return (
			<PageContainer>
				<section className="bg-half-260 bg-primary d-table w-100 gradient-all">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<div className="title-heading text-center mt-4">
									<h1 className="display-4 fw-bold text-white mb-3">Atlas</h1>
									<p className="para-desc mx-auto text-white-50">
										Atlas je speciální rozcestník pro všechny odkazy na serverové Dynmapy nebo i jiné stránky týkající se serveru.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section bg-light">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-12">
								<div className="features-absolute">
									<div className="row">
										<div className="col-lg-4 col-md-6 col-12">
											<MapCardBox
												id="survival"
												imageSource={backpack}
												title="Survival: Classic"
												titleLink="https://survival-dynmap.craftmania.cz"
												serverVersion="1.20"
												description="Dynmapa našeho Survival ekonomického světa a spawnu."
												active={true}
												mapLink="https://survival-dynmap.craftmania.cz"
											/>
										</div>

										<div className="col-lg-4 col-md-6 col-12 mt-4 mt-md-0 pt-2 pt-md-0">
											<MapCardBox
												id="oneblock"
												imageSource={horse}
												title="Oneblock"
												titleLink="#"
												serverVersion="1.20"
												description="Dynmapa našeho Skyblock ekonomického serveru s ostrovy."
												active={false}
												mapLink="#"
											/>
										</div>

										<div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
											<MapCardBox
												id="creative"
												imageSource={bunny}
												title="Creative"
												titleLink="https://creative-dynmap.craftmania.cz"
												serverVersion="1.20"
												description="Dynmapa pro náš Creative server s nekonečnými pozemky."
												active={true}
												mapLink="https://creative-dynmap.craftmania.cz"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12">
								<div className="section-title text-center mb-4 pb-2">
									<h4 className="mb-4">Otázky? Odpovědi? Informace?!</h4>
									<p className="para-desc mx-auto text-muted">
										Zde je výpis pár nejčastějších otázek ohledně našich map, najdeš zde pár tipů a triků a hlavně odpovědi na nejčastější otázky!
									</p>
								</div>
							</div>
							<div className="col-lg-9 mt-4 pt-2">
								<div className="accordion" id="accordionExample">
									<Accordion
										id={1}
										title={"Jak se zobrazím nebo skryju na mapě?"}
										description={"Každý hráč je na mapě v základu zobrazovaný, pokud nechceš být vidět stačí dát <code>SHIFT</code> nebo <b>Invisible Potion<b>."}
									/>
									<Accordion
										id={2}
										title={"Jak přidám bod na mapu? Př. moje město"}
										description={"Pokud chceš přidat bod na mapu, stačí si vytvořit ticket na našem Discord serveru a napsat pozici, kde bod chceš.<br>V případě delší neaktivity v části mapy, kde se bude nacházet ikona, bude ikona bez jakékoliv náhrady smazána."}
									/>
									<Accordion
										id={3}
										title={"Část mapy, kde jsem je pokažená nebo né zcela vyrendrovaná?!"}
										description={"Server generuje mapu postupně. Někdy to trvá déle, někdy to je hned. Pokud bude část mapy, kde máš stavbu blbě vyrendrována můžeš si požádat o manuální přerendrování. Avšak dymapa automaticky renderuje části mapy vždy, když položíš/zníčíš bloky."}/>
								</div>
							</div>
						</div>
					</div>
				</section>
			</PageContainer>
		);
	}

}

export default Homepage;
