import React from "react";
import { Component } from "react";

class FooterBar extends Component<any, any> {

	public render() {
		return (
			<footer className="footer footer-bar">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-sm-12">
							<div className="text-sm-left">
								<p className="mb-0">© {new Date().getFullYear()} CraftMania.cz. Navrženo a vytvořeno s <i
									className="fas fa-heart text-danger"/> od
									<a href="https://github.com/MrWakeCZ"
										target="_blank" rel="noopener noreferrer"
										className="text-reset"> Wake
									</a>.
								</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		);
	}

}

export default FooterBar;
