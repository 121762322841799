import React, { Component } from "react";

interface IAccordionProps {
	id: number;
	title: string;
	description: any;
}

class Accordion extends Component<IAccordionProps, any> {

	public render() {
		return (
			<div className="accordion-item rounded mb-2" style={{border: 0, boxShadow: "0 0 3px rgba(60,72,88,0.15)"}}>
				<h2 className="accordion-header" id={"heading" + this.props.id}>
					<button className="accordion-button border-0 bg-light collapsed"
							type="button" data-bs-toggle="collapse"
							data-bs-target={"#collapse" + this.props.id} aria-expanded="false"
							aria-controls={"collapse" + this.props.id}>
						{this.props.title}
					</button>
				</h2>
				<div id={"collapse" + this.props.id} className="accordion-collapse border-0 collapse"
					aria-labelledby={"heading" + this.props.id}>
					<div className="accordion-body text-muted bg-white">
						<div dangerouslySetInnerHTML={{ __html: this.props.description }} />
					</div>
				</div>
			</div>
		);
	}
}

export default Accordion;
