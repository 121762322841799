import React from "react";
import { Component } from "react";

interface MenuState {
	activeClass: null | string;
}

class Menu extends Component<any, MenuState> {

	public constructor(props: any) {
		super(props);
		this.state = {
			activeClass: null
		};
	}

	public async componentDidMount() {
		window.addEventListener('scroll', () => {
			let activeClass: string | null = null;
			if (window.scrollY > 50) {
				activeClass = 'nav-sticky';
			}
			this.setState({ activeClass });
		});
	}

	public render() {
		return (
			<header id="topnav" className={"defaultscroll sticky active " + this.state.activeClass}>
				<div className="container">
					<div>
						<a className="logo" href="/">
							<img src="https://static.craftmania.cz/logo/logo_noBG_full.png" height="60" alt=""/>
						</a>
					</div>

					<div id="navigation" className="active">
						<ul className="navigation-menu nav-light">
							<li><a href="https://craftmania.cz" rel="noopener noreferrer">Domů</a> </li>
							<li className="has-submenu last-elements">
								<a href="/" aria-disabled={true}>Hlasování</a><span className="menu-arrow"/>
								<ul className="submenu">
									<li><a href="https://czech-craft.eu/server/craftmania/vote/" target="_blank" rel="noopener noreferrer">Czech-Craft (2h)</a></li>
									<li><a href="https://www.minecraft-list.cz/server/craftmania/vote" target="_blank" rel="noopener noreferrer">Minecraft-List (2h)</a></li>
									<li><a href="https://craftlist.org/craftmania" target="_blank" rel="noopener noreferrer">CraftList (2h)</a></li>
									<li><a href="https://minecraftservery.eu/server/453&nick=" target="_blank" rel="noopener noreferrer">MinecraftServery (2h)</a></li>
								</ul>
							</li>
							<li><a href="https://discord.gg/craftmania" target="_blank" rel="noopener noreferrer">Discord</a></li>
							<li><a href="https://wiki.craftmania.cz/">Wiki</a></li>
							<li><a href="https://stats.craftmania.cz/">Statistiky</a></li>
							<li><a href="https://changelog.craftmania.cz/">Changelog</a></li>
						</ul>
						<div className="buy-menu-btn d-none">
							<a href="https://store.craftmania.cz" target="_blank" rel="noopener noreferrer" className="btn btn-primary shadow-none">Store</a>
						</div>
					</div>
				</div>
			</header>
		);
	}

}

export default Menu;
