import * as React from "react";
import ReactDOM from 'react-dom';
import Homepage from "./Homepage";

ReactDOM.render(
	<React.Fragment>
		<Homepage/>
	</React.Fragment>,
document.getElementById('root')
);
