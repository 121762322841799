import React, { Component } from "react";

interface MapCardBoxProps {
	id: string;
	imageSource: any;
	title: string;
	titleLink: string;
	serverVersion: string;
	description: string;
	active: boolean;
	mapLink: string;
}

class MapCardBox extends Component<MapCardBoxProps, any> {
	public render() {
		return(
			<div id={this.props.id} className="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
				<div className="d-lg-block position-relative">
					<img className="img-fluid mx-auto d-block construction-img" src={this.props.imageSource} alt=""/>
				</div>

				<div className="card-body p-0 content">
					<h5 className="mt-4"><a href={this.props.titleLink} className="title text-dark">{this.props.title}</a></h5>
					<p className="text-muted">{this.props.description}</p>

					{
						this.props.active ?
							<div className="buy-menu-btn">
								<a href={this.props.mapLink} target="_blank" rel="noopener noreferrer" className="btn btn-primary shadow-none">Zobrazit</a>
							</div> :
							<div className="buy-menu-btn">
								<a href="/" target="_blank" rel="noopener noreferrer" className="btn btn-dark shadow-none disabled">Již brzy</a>
							</div>
					}
				</div>
			</div>
		);
	}
}

export default MapCardBox;
