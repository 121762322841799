import React from "react";
import { Component } from "react";
import Menu from "./Menu";
import FooterBar from "./FooterBar";
import Footer from "./Footer";

class PageContainer extends Component<any, any> {

	public async componentDidMount() {
		window.scrollTo(0, 0);
	}

	public render() {
		return(
			<div>
				<Menu/>
				{this.props.children}
				<Footer/>
				<FooterBar/>
			</div>
		);
	}

}

export default PageContainer;
