import React from "react";
import { Component } from "react";

class Footer extends Component<any, any> {

	public render() {
		return (
			<footer className="footer">
				<div className="container">
					<div className="row">

						<div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
							<h4 className="text-light footer-head">Užitečné odkazy</h4>
							<ul className="list-unstyled footer-list mt-4">
								<li>
									<a href="https://news.craftmania.cz/"
										className="text-foot" target="_blank" rel="noopener noreferrer">
										<i className="mdi mdi-chevron-right mr-1"/> Novinky
									</a>
								</li>
								<li>
									<a href="https://wiki.craftmania.cz/"
										className="text-foot" target="_blank" rel="noopener noreferrer">
										<i className="mdi mdi-chevron-right mr-1"/> Wiki
									</a>
								</li>
								<li>
									<a href="https://stats.craftmania.cz/"
										className="text-foot" target="_blank" rel="noopener noreferrer">
										<i className="mdi mdi-chevron-right mr-1"/> Statistiky
									</a>
								</li>
								<li>
									<a href="https://banlist.craftmania.cz/"
										className="text-foot" target="_blank" rel="noopener noreferrer">
										<i className="mdi mdi-chevron-right mr-1"/> Banlist
									</a>
								</li>
							</ul>
						</div>

						<div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
							<h4 className="text-light footer-head">Podmínky a kontakt</h4>
							<ul className="list-unstyled footer-list mt-4">
								<li>
									<a href="https://wiki.craftmania.cz/pravidla-serveru/"
										className="text-foot"><i className="mdi mdi-chevron-right mr-1"/> Pravidla serveru
									</a>
								</li>
								<li>
									<a href="https://wiki.craftmania.cz/povolene-a-zakazane-mody/"
										className="text-foot"><i className="mdi mdi-chevron-right mr-1"/> Povolené a zakázané módy
									</a>
								</li>
								<li>
									<a href="https://wiki.craftmania.cz/pravidla-o-ochrane-udaju/"
										className="text-foot"><i className="mdi mdi-chevron-right mr-1"/> Ochrana osobních údajů
									</a>
								</li>
							</ul>
						</div>

						<div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
							{ /* JEBAIT */ }
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

export default Footer;
